<template>
    <div class="col-6 col-xs-12 col-md-6">
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                    <v-text-field
                        :label="$t('message.filterResults')"
                        :value="searchTerm"
                        autocomplete="off"
                        class="mt-2 mb-0 force-text-left"
                        prepend-inner-icon="mdi-filter-outline"
                        @change="searchTerm = $event"
                    />
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="resetSearch"><v-icon>refresh</v-icon></v-btn>
                    <ExportTable
                        :has-add-function="true"
                        :cols-to-delete="colsToDelete"
                        class="mt-0 mr-0"
                        file-name="buyerRemarks"
                        table-id="buyerRemarksTable"
                        style="padding-top: 2px !important;"
                        @add-action="editDialog = true"
                    />
                </v-col>
            </v-row>
        </v-sheet>
        <div style="position: relative">
            <v-overlay
                :value="loading.buyerRemarks"
                absolute
                opacity="0.15"
            >
                <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            size="40"
                            width="6"
                        />
                    </v-col>
                </v-row>
            </v-overlay>
            <v-data-table
                :footer-props="{
                    itemsPerPageOptions: [20,50,-1],
                    showCurrentPage: true,
                    showFirstLastPage: true
                }"
                :headers="headers"
                :height="tableHeight"
                :items="allInternalRemarks"
                :items-per-page="-1"
                :search="searchTerm"
                :options.sync="tableOptions"
                :sort-by="'InternalRemark.title'"
                calculate-widths
                class="mt-0 appic-table-light specification-table"
                dense
                fixed-header
                id="buyerRemarksTable"
                item-key="InternalRemark.id"
            >
                <template v-slot:item.InternalRemark.title="{ item }">
                    <span class="font-weight-bold">{{ item.InternalRemark.title }}</span>
                </template>
                <template v-slot:item.InternalRemark.id="{ item }">
                    <div class="text-center">
                        <v-menu>
                            <template v-slot:activator="{ on: menu }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on: tooltip }">
                                        <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                            <v-icon>more_vert</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('message.moreActions') }}</span>
                                </v-tooltip>
                            </template>
                            <v-list dense>
                                <v-list-item class="font-sm" @click="openEditBuyerRemarkDialog(item.InternalRemark.id, item.InternalRemark.title)">
                                    <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateBuyerRemark') }}
                                </v-list-item>
                                <v-list-item class="font-sm" @click="deleteBuyerRemark(item.InternalRemark.id, item.InternalRemark.title)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteBuyerRemark') }}</v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
            </v-data-table>
        </div>
        <AddBuyerRemark
            :buyer-remark="selectedBuyerRemark"
            :buyer-remark-id="selectedBuyerRemarkId"
            :dialog.sync="editDialog"
            :page-key="pageKey"
            :update-mode="updateMode"
            @dialog-closed="editDialogClosed"
            @create-done="buyerRemarkCreated"
            @update-done="buyerRemarkUpdated"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

const AddBuyerRemark = () => import("Components/Appic/AddBuyerRemark");
const ExportTable = () => import("Components/Appic/ExportTable");

export default {
    name: "BuyerRemarksList",
    components: {AddBuyerRemark, ExportTable},
    props:  ['addDialog','reload'],
    data() {
        return {
            colsToDelete: {
                start_col: 0,
                ncols: 1
            },
            dialogs: {
                error: false,
                error_message: ""
            },
            editDialog: false,
            editSortDialog: false,
            hideDefaultFooter: true,
            loading: {
                fetch: false,
                filterResults: false,
                buyerRemarks: false
            },
            searchField: null,
            searchTerm: null,
            selectedBuyerRemark: null,
            selectedBuyerRemarkId: null,
            selectedBuyerRemarkType: null,
            tableHeight: '500',
            tableOptions: {
                page: 1
            },
            totalBuyerRemarks: 0,
            updateMode: false,
            updateSortMode: false
        }
    },
    computed: {
        ...mapGetters('internalremark',{
            allInternalRemarks: 'allInternalRemarks'
        }),
        headers () {
            return [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'InternalRemark.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.remark'),
                    value: 'InternalRemark.title',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                }
            ]
        },
        pageKey() {
            return Math.floor(Math.random() * 100)
        },
        pageSortKey() {
            return Math.floor(Math.random() * 100)
        }
    },
    methods: {
        ...mapActions('internalremark',{
            deleteInternalRemarkById: 'deleteInternalRemarkById',
            getAllInternalRemarks: 'getAllInternalRemarks',
            resetState: 'resetState'
        }),
        buyerRemarkCreated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        },
        buyerRemarkUpdated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        },
        async deleteBuyerRemark(val, title) {
            if(await this.$root.$confirm(this.$t('message.deleteBuyerRemark') + ' ' +  title, this.$t('message.confirmations.continueBuyerRemarkCancelAction'), {color: 'orange'})){
                this.deleteInternalRemarkById(val)
                    .then((response) => {
                        if(response.status == 'success'){
                            this.$toast.success(this.$t('message.successes.buyerRemarkDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.resetState()
                            this.getAllInternalRemarks()
                        } else {
                            this.$toast.error(this.$t('message.errors.buyerRemarkNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch((err) => {
                        this.$toast.error(err,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        editDialogClosed () {
            this.editDialog = false;
            if(this.updateMode == false){
                this.$emit('add-dialog-closed')
            } else {
                this.updateMode = false
                this.selectedBuyerRemark = null
                this.selectedBuyerRemarkId = null
            }
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (285);
        },
        openCreateBuyerRemarkDialog () {
            this.updateMode = false
            this.editDialog = true
        },
        openEditBuyerRemarkDialog (buyerRemarkId, buyerRemark) {
            this.selectedBuyerRemarkId = buyerRemarkId
            this.selectedBuyerRemark = buyerRemark
            this.updateMode = true
            this.editDialog = true
        },
        resetSearch() {
            this.searchTerm = null
            this.getAllInternalRemarks()
        }
    },
    watch: {
        addDialog(value) {
            if(value) {
                this.openCreateBuyerRemarkDialog ()
            }
        }
    },
    created() {
        this.resetState()
        if(this.allInternalRemarks.length == 0) {
            this.loading.buyerRemarks = true
            this.getAllInternalRemarks()
                .then(() => {
                    this.loading.buyerRemarks = false
                })
                .catch(() => {
                    this.loading.buyerRemarks = false
                })
        }
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.handleResize()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style>
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.sort-id {
    cursor: pointer;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: lightgray;
    display: inline-flex !important;
    align-items: center;
    vertical-align: middle;
    flex-direction: column !important;
    justify-content: center;
    position: relative;
}
</style>